import React, { useState, useEffect } from "react";
import axios from "axios";
import DataGrid, {
  Column,
  Sorting,
  GroupPanel,
  SearchPanel,
  HeaderFilter,
  FilterPanel,
  ColumnChooser,
  StateStoring,
  Pager,
  Selection
} from "devextreme-react/data-grid";
import { Box, Paper, Grid, LinearProgress, Divider, Typography, ButtonGroup, Button, Drawer, TextField } from '@material-ui/core'
import { DateBox } from "devextreme-react";
import { useHistory } from "react-router-dom";
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import QueueItem from "./QueueItem";

export default function QueueList(props) {
  const [displayMode, setDisplayMode] = useState('grid')
  const [selectedRowKeys, SetSelectedRowKeys] = useState([]);
  const [error, SetError] = useState();
  const [apiLoading, SetApiLoading] = useState(false);
  const [overlayQueueId, setOverlayqueueId] = useState();

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    SetSelectedRowKeys(selectedRowKeys);
  }

  const handleChange = (event, newAlignment) => {
    setDisplayMode(newAlignment);
  };

  const editDetails = e => {
    //history.push(`/queue/queue/${e.row.data.id}`);
    console.log("here");
    setOverlayqueueId(e.row.data.id);
  };

  const updateStatus = status => {
    SetApiLoading(true);
    selectedRowKeys.forEach(id => {
      axios
        .post("/api/Queue/QueueUdpate", { id: id, status })
        .catch(err => console.log(err))
        .then(() => { SetApiLoading(false) });;
    });
  };

  const retry = () => {
    SetApiLoading(true);
    axios.post("/api/Queue/retry", { selectedRowKeys })
      .catch(e => SetError(e.response))
      .then(() => { SetApiLoading(false) });
  }

  return (
    <>
      <Box m={2} display='flex' flexDirection='column'>
        <Box>
          <Grid container spacing={1} direction="row" justify='space-between' alignItems='center'>
            <Grid item lg={8}>
              <Paper>
                <Box p={2} display='flex'>
                  <div className={'dx-field'}>
                    <div className={'dx-field-label'}>From Date</div>
                    <div className={'dx-field-value'}>
                      <DateBox openOnFieldClick
                        type={'date'}
                        value={props.fromDate}
                        label=''
                        onValueChanged={e => {
                          props.setFromDate(e.value);
                        }}
                      />
                    </div>
                  </div>
                  <TextField label='C0#:'/>
                </Box>
              </Paper>
            </Grid>
            <Grid item lg={3}>
              {props.isLoading && <LinearProgress />}
            </Grid>
            <Grid item lg={1}>
              <ToggleButtonGroup exclusive size="small" value={displayMode} onChange={handleChange}>
                <ToggleButton value="grid">Grid</ToggleButton>
                <ToggleButton value="list">List</ToggleButton>
              </ToggleButtonGroup>
            </Grid>

            {selectedRowKeys && selectedRowKeys.length > 0 &&
              <Box px={1}>
                <Paper>
                  <Box m={1}>
                    <Typography onClick={() => SetSelectedRowKeys([])} >A total of {selectedRowKeys.length} rows are selected</Typography>
                    <Box display="flex" alignItems="flex-end">
                      <Box mr={2} style={{ flex: 1 }} py={2}>
                        <Button variant="contained" color="primary" onClick={retry} enabled={!apiLoading}>Retry</Button>
                      </Box>
                      <Box>
                        {apiLoading && <LinearProgress />}
                      </Box>
                      <Box style={{ flex: 1 }} py={2}>
                        <Typography component="p" color="textSecondary" inline>Update Queue Status</Typography>
                        <ButtonGroup variant="contained" aria-label="contained button group">
                          <Button onClick={() => updateStatus("Pending")}>Pending</Button>
                          <Button onClick={() => updateStatus("Completed")}>Completed</Button>
                          <Button onClick={() => updateStatus("Cancel")}>Cancel</Button>
                        </ButtonGroup>
                      </Box>
                    </Box>
                  </Box>
                </Paper>
              </Box>
            }
          </Grid>
        </Box>
        <Box flexGrow={1} height='100%' overflow='auto' mt={3}>
          <Grid item lg={12}>
            {displayMode === 'grid' && <DataGrid
              height='100%'
              keyExpr="id"
              onSelectionChanged={onSelectionChanged}
              columnAutoWidth={true}
              columnResizingMode={"widget"}
              highlightChanges
              showBorders
              dataSource={props.queues}
              showColumnLines
              showRowLines
              allowColumnReordering
              allowColumnResizing
              rowAlternationEnabled
              hoverStateEnabled
              repaintChangesOnly
            >
              <SearchPanel visible={true} highlightCaseSensitive={true} />
              <GroupPanel visible={true} />
              <Sorting mode={"multiple"} />
              <FilterPanel visible />
              <HeaderFilter visible />
              <ColumnChooser enabled />
              <StateStoring enabled type='localStorage' storageKey='queueListGrid' />
              <Pager showPageSizeSelector showInfo />
              <Selection
                mode='multiple'
                selectAllMode='allPages'
                showCheckBoxesMode='onClick' />

              <Column
                type={"buttons"}
                width={110}
                buttons={[
                  {
                    hint: "Clone",
                    icon: "edit",
                    onClick: editDetails
                  }
                ]}
              />

              <Column dataField="status" />
              <Column dataField="id" />
              <Column dataField="conum" />
              <Column dataField="description" />
              <Column dataField="requestedOn" dataType="date"
                format="MM/dd/yyyy hh:mm a" />
              <Column dataField="completedOn" dataType="date"
                format="MM/dd/yyyy hh:mm a" />
              <Column dataField="requestedBy" />
              <Column dataField="requestedBy_EMPCONUM" visible={false} />
              <Column dataField="requestedBy_EMPNUM" visible={false} />
              <Column dataField="requestedUserType" />
              <Column dataField="requestedMachineName" visible={false} />
              <Column dataField="processingDuration" />
              <Column dataField="priority" />
              <Column dataField="errorCount" />
              <Column dataField="errorMessage" />
              <Column dataField="errorDateTime" dataType="date"
                format="MM/dd/yyyy hh:mm a" />
            </DataGrid>
            }
            {displayMode === 'list' && props.queues.map(row =>
              <>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <span style={{ flexGrow: 1 }}>{row.id}</span>
                  <span style={{ flexGrow: 1 }}>{row.status}</span>
                  <span style={{ flexGrow: 2 }}>{row.description}</span>
                  <span style={{ flexGrow: 1 }}>{row.conum}</span>
                  <span style={{ flexGrow: 1 }}>{row.conum}</span>
                </div>
                <Divider />
              </>
            )}
          </Grid>
        </Box>
      </Box >


      <Drawer anchor='right'
        open={overlayQueueId}
        onClose={() => setOverlayqueueId(null)}
        width={'75vw'}
        openSecondary={true}
        docked={true}>
        <div style={{ width: '85vw' }}>
          {overlayQueueId && <QueueItem queueId={overlayQueueId} />}
        </div>
      </Drawer>
    </>
  );
}
