import React from 'react'
import { Box, Grid, Paper } from '@material-ui/core'
import { DateBox } from 'devextreme-react';
import QueueDetailsGrid from './QueueDetailsGrid';

export default function QueueDetailsList(props) {
    return (
        <Box m={3}>
            <Grid container spacing={3} direction="row">
                <Grid item lg={3}>
                    <Paper>
                        <Box p={2}>
                            <div className={'dx-field'}>
                                <div className={'dx-field-label'}>From Date</div>
                                <div className={'dx-field-value'}>                                    
                                    <DateBox openOnFieldClick
                                        type={'date'}
                                        value={props.fromDate}
                                        onValueChanged={e => {
                                            props.setFromDate(e.value);
                                        }}
                                    />
                                </div>
                            </div>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item lg={12}>
                    <QueueDetailsGrid fromDate={props.fromDate}/>
                </Grid>
            </Grid>

        </Box>
    )
}