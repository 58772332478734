import React from "react";
import TicketSidbar from "./componenets/ticketSidebar";
import { Switch, Route, BrowserRouter, Redirect } from "react-router-dom";
import SetTicketOrg from "./componenets/setTicketOrg";
import queueLayout from "./componenets/Queue/QueueLayout";
import CssBaseline from '@material-ui/core/CssBaseline';

const App = () => (
  <React.Fragment>
    <CssBaseline />
    <BrowserRouter>
      <Switch>
        <Redirect from="/q" to="/queue" exact />
        <Route path="/queue" component={queueLayout} />
        <Route exact path="/" component={TicketSidbar} />
        <Route path="/SetTicketOrg" component={SetTicketOrg} />
      </Switch>
    </BrowserRouter>
  </React.Fragment>
);

export default App;
