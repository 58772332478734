import React from "react";
import queryString from 'query-string';
import { Grid, TextField, Button, Paper } from '@material-ui/core';
import {
    Grid as DataGrid,
    Table,
    TableHeaderRow,
    TableSelection,
    TableColumnResizing,
    TableColumnReordering,
    DragDropProvider
} from "@devexpress/dx-react-grid-material-ui";
import {
    SortingState,
    IntegratedSorting,
    SelectionState
} from '@devexpress/dx-react-grid';
import axios from 'axios';

class SetTicketOrg extends React.Component {
    constructor(props) {
        super(props);
        const values = queryString.parse(props.location.search);
        console.log('values', values);
        this.ticketId = values.ticketId;
        this.state = {
            searchText: values.email && values.email !== 'null' ? values.email : '',
            data: [],
            defaultColumnWidths: [
                { columnName: 'conum', width: 85 },
                { columnName: 'coName', width: 240 },
                { columnName: 'prContact', width: 180 },
                { columnName: 'coPhone', width: 100 },
                { columnName: 'coModem', width: 100 },
                { columnName: 'coFax', width: 100 },
                { columnName: 'fedId', width: 90 },
                { columnName: 'coEmail', width: 240 },
                { columnName: 'coStatus', width: 240 }
            ],
            selection: []
        };
        this.searchTextChanged = this.searchTextChanged.bind(this);
        this.searchComapnies = this.searchComapnies.bind(this);
        this.onSearchClick = this.onSearchClick.bind(this);
        this.changeSelection = this.changeSelection.bind(this);
    }

    componentDidMount = () => {
        console.log('componentDidMount');
        var zafClient = window.ZAFClient.init(function (context) {
        });
        this.zafClient = zafClient;

        if (this.state.searchText) {
            this.searchComapnies();
        }

        // let c = this;
        // zafClient.on('setSearchText', function setSearchText(data) {
        //     console.log(data.ticketId);
        //     c.ticketId = data.ticketId;
        //     console.log('[SetTicketOrg] setSearchText', data.email);
        //     c.setState({ searchText: data.email });
        //     if (data.email) {
        //         c.searchComapnies();
        //     }
        //     zafClient.off('setSearchText', setSearchText);
        // });

        // zafClient.on('app.registered', function registered() {
        //     var ticketClientPromise = zafClient.get('instances').then(function (instancesData) {
        //         let instance = Object.keys(instancesData.instances).filter(function (key) {
        //             return instancesData.instances[key].location === 'ticket_sidebar';
        //         });
        //         console.log('[SetTicketOrg] triggering modalReady', instance[0]);
        //         zafClient.instance(instance[0]).trigger('modalReady', zafClient._appGuid);
        //     });
        //     zafClient.off('app.registered', registered);
        // });
    }

    searchTextChanged(e) {
        this.setState({ searchText: e.target.value });
    }

    catchReturn = ev => {
        if (ev.key === 'Enter') {
            this.onSearchClick();
            ev.preventDefault();
        }
    }

    onSearchClick(e) {
        this.searchComapnies();
    }

    searchComapnies() {
        axios.get('/api/FdUtils/SearchCompanies', {
            params: {
                searchText: this.state.searchText
            }
        }
        ).then(data => {
            this.setState({ data: data.data });
        }).catch(err => {
            console.log(err);
        });
    }

    changeSelection(sel) {
        this.setState(function (state, props) {
            return { selection: sel.length === 0 ? [] : [sel.pop()] };
        });
    }

    setTicketConum = () => {
        let client = this.zafClient;
        axios.post(`/api/ZendeskWebHooks/SetTicketConum?ticketId=${this.ticketId}&conum=${this.state.data[this.state.selection[0]].conum}`)
            .then(d => {
                client.invoke('destroy');
            }).catch(err => {
                console.log(err);
                client.invoke('notify', 'error setting ticket co# ' + err);
            });
    }

    render() {
        const { defaultColumnWidths, selection } = this.state;
        return (
            <Grid container>
                <Grid item xs={12}>
                    <TextField
                        id="standard-search"
                        label="Search field"
                        type="search"
                        value={this.state.searchText}
                        onChange={this.searchTextChanged}
                        autoFocus
                        style={{ width: 300 }}
                        onKeyPress={this.catchReturn}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={this.onSearchClick}
                        disabled={!this.state.searchText || this.state.searchText.length < 4}
                        style={{ marginTop: 14, marginLeft: 5 }}>
                        Search
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <DataGrid
                        rows={this.state.data}
                        columns={[
                            { name: "conum", title: "Co#" },
                            { name: "coName", title: "Name" },
                            { name: "prContact", title: "Pr Contact" },
                            { name: "coPhone", title: "Phone" },
                            { name: "coModem", title: "Model" },
                            { name: "coFax", title: "Fax" },
                            { name: "fedId", title: "Fed Id" },
                            { name: "coEmail", title: "Emails" },
                            { name: "coStatus", title: "Status" }
                        ]}
                    >
                        <SortingState defaultSorting={[{ columnName: 'conum', direction: 'asc' }]} />
                        <IntegratedSorting />
                        <DragDropProvider />
                        <SelectionState selection={selection}
                            onSelectionChange={this.changeSelection} />
                        <Table />
                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                        <TableColumnReordering defaultOrder={['conum', 'coName', 'prContact', 'coPhone', 'coModem', 'coFax', 'fedId', 'coEmail', 'coStatus']} />
                        <TableHeaderRow showSortingControls />
                        <TableSelection showSelectionColumn={false}
                            selectByRowClick
                            highlightRow />
                    </DataGrid>
                </Grid>

                <Grid item xs={12}>
                    <Button
                        variant="contained"
                        color="default"
                        onClick={this.setTicketConum}
                        disabled={this.state.selection.length !== 1 || this.state.data.length === 0}
                        style={{ marginTop: 14, marginLeft: 5, position: "absolute", right: 5 }}>
                        Set Company {this.state.selection.length > 0 ? '(' + this.state.data[this.state.selection[0]].conum + ')' : null}
                    </Button>
                </Grid>
            </Grid>
        );
    }
}

export default SetTicketOrg