/*global client, ZAFClient*/

export default async function getTicketInfo() {
    let result = {
        zafClient: null,
        ticketId: null,
        conum: null,
        tags: null,
        userExternalId: null,
        userId: null,
        requester: null,
        comment: null
    };

    console.log('[ticketInfo]', ZAFClient);
    var zafClient = ZAFClient.init();

    // zafClient.on('ticket.save', function () {
    //     return new Promise(function (resolve, reject) {
    //         return zafClient.get('ticket.subject').then(function (data) {
    //             if (data['ticket.subject'] === 'Created In Front Desk') {
    //                 //zafClient.invoke('notify', "Please take ownership of the ticket before executing this action", "error", 5000);
    //                 reject('Please update the ticket subject');
    //             } else {
    //                 zafClient.invoke('notify', "Ticket is assigned", "error", 5000);
    //                 resolve(true);
    //             }
    //         });
    //     });
    // });

    result.zafClient = zafClient;

    console.log('[ticketInfo2]', zafClient);
    let data = await zafClient.get('ticket.id');
    result.ticketId = data['ticket.id'];

    data = await zafClient.get('ticket.organization');
    var org = data['ticket.organization'];
    if (typeof org !== "undefined" && org !== null) {
        result.conum = org.externalId;
    }

    data = await zafClient.get('currentUser');
    var user = data['currentUser'];
    if (typeof user !== "undefined" && user.externalId !== null) {
        result.userExternalId = user.externalId;
        result.userId = user.id;
    }

    data = await zafClient.get('ticket.requester');
    result.requester = data['ticket.requester'];

    data = await zafClient.get('ticket.tags');
    result.tags = data['ticket.tags'];

    zafClient.invoke('resize', { height: '250px' });

    return result;
}