import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import QueueDetailsGrid from '../QueueDetails/QueueDetailsGrid';
import Title from '../Title'
import { makeStyles, Divider, TextField, CircularProgress } from "@material-ui/core";
import useFetchQueue from './useFetchQueue';

const useStyles = makeStyles(theme => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: '100%'
    }, button: {
        margin: theme.spacing(1),
    },
}))

function MyTextField(props) {
    let value = props.value || "";
    if (props.value === 0) {
        value = "0";
    }
    if (props.date && props.value) {
        value = new Date(props.value).toLocaleString();
    }
    return <TextField
        label={props.label}
        value={value}
        margin="dense"
        variant="outlined"
        color="secondary"
        InputProps={{
            readOnly: true,
        }} />
}

function QueueItem(props) {
    const classes = useStyles();
    const { queues, isLoading, setQueue } = useFetchQueue({ queueId: props.queueId || props.match.params.Id });
    let queue = queues[0];

    const updateStatus = status => {
        axios
            .post("/api/Queue/QueueUdpate", { id: queue.id, status })
            .then(data => {
                setQueue(data.data);
            })
            .catch(err => console.log(err));
    };

    const requeue = () => {
        axios
            .post(`/api/Queue/Requeue/${queue.id}`)
            .then(data => {
                setQueue(data.data);
            })
            .catch(err => console.log(err));
    };

    const retry = () => {
        axios.post("/api/Queue/retry", {SelectedRowKeys: [queue.id] })
          .catch(e => console.log(e));
      }

    const showLogs = () => {
        window.open(`http://appserver:5341/#/events?signal=signal-193&filter=QueueId%20%3D%20'${queue.id}'&autorefresh`, "_blank");
    };

    if (!queue || isLoading) {
        return <CircularProgress />
    }

    return (
        <Box pt={2} px={2} height={'100%'} width={'100%'}>
            <Grid container spacing={3} alignItems="stretch" direction="row">
                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={classes.paper}><Title>Queue Status</Title>
                        <Box display="flex" justifyContent='space-between'>
                            <Typography variant="h6">{queue.status}</Typography>
                            <Button color='secondary' onClick={showLogs}>Show Logs</Button>
                        </Box>
                        <Box style={{ flex: 1 }} py={2}>
                            <Typography component="p" color="textSecondary" inline>Update Queue Status</Typography>
                            <ButtonGroup variant="contained" aria-label="contained button group">
                                <Button onClick={() => updateStatus("Pending")}>Pending</Button>
                                <Button onClick={() => updateStatus("Completed")}>Completed</Button>
                                <Button onClick={() => updateStatus("Cancel")}>Cancel</Button>
                            </ButtonGroup>
                        </Box>

                        <Button variant="outlined" color="primary" onClick={requeue} className={classes.button}>Requeue</Button>
                        <Button variant="contained" color="primary" onClick={retry} className={classes.button}>Retry</Button>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={classes.paper}>
                        <Title>Queue ({queue.description})</Title>
                        <MyTextField label="Id" value={queue.id} />
                        <MyTextField label="Conum" value={queue.conum} />
                        <MyTextField label="Requested On" value={queue.requestedOn} date />
                        <MyTextField label="Completed On" value={queue.completedOn} date />
                        <MyTextField label="Priority" value={queue.priority} />
                        <MyTextField label="Processing Duration" value={queue.processingDuration} />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={classes.paper}>
                        <Title>Requester</Title>
                        <MyTextField label="User" value={queue.requestedBy} />
                        <MyTextField label="Emp Conum" value={queue.requestedBy_EMPCONUM} />
                        <MyTextField label="Emp#" value={queue.requestedBy_EMPNUM} />
                        <MyTextField label="User Type" value={queue.requestedUserType} />
                        <MyTextField label="Machine Name" value={queue.requestedMachineName} />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4} lg={3}>
                    <Paper className={classes.paper}>
                        <Title>Error</Title>
                        <MyTextField label="Count" value={queue.errorCount} />
                        <MyTextField label="Date" value={queue.errorDateTime} date />
                        <MyTextField label="Message" value={queue.errorMessage} />
                    </Paper>
                </Grid>

            </Grid>
            <Grid item lg={12}>
                <Paper>
                    <Box my={2} py={2}>
                        <Box ml={3}>
                            <Title>Queue Details</Title>
                        </Box>
                        {queue.id && <QueueDetailsGrid queueId={queue.id} />}
                    </Box>
                </Paper>
            </Grid>
        </Box >
    );
}

export default QueueItem;
