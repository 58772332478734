import React, { useState, useEffect } from "react";
import axios from "axios";
import Button from "@material-ui/core/Button";
import { Box, ButtonGroup, Grid } from "@material-ui/core";
import Title from './../Title'
import PropertiesDisplay from "../../PropertiesDisplay";

function QueueDetailItem(props) {
  const [queueDetail, setQueueDetail] = useState({});
  const [queueDetailExt, setQueueDetailExt] = useState({});

  useEffect(() => {
    axios
      .get(`/api/Queue/QueueDetails?QueueDetailId=${props.id}`)
      .then(data => {
        setQueueDetail(data.data[0]);
      })
      .catch(err => console.log(err));

    axios
      .get(`/api/Queue/QueueDetails/ext?QueueDetailId=${props.id}`)
      .then(data => {
        console.log(data);
        setQueueDetailExt(data.data);
      })
      .catch(err => console.log(err));
  }, [props.id]);

  const updateStatus = status => {
    axios
      .post("/api/Queue/QueueDetailsUdpate", { id: queueDetail.id, status })
      .then(data => {
        setQueueDetail(data.data);
      })
      .catch(err => console.log(err));
  };

  const showLogs = () => {
    window.open(`http://appserver:5341/#/events?signal=signal-193&filter=Id%20%3D%20'${queueDetail.id}'&autorefresh`, "_blank");
  };

  return <Box m={2}>
    <Title>Queue Detail</Title>
    <ButtonGroup variant="contained" aria-label="contained button group">
      <Button onClick={() => updateStatus("Cancel")}>Cancel</Button>
      <Button onClick={() => updateStatus("Pending")}>Pending</Button>
      <Button onClick={() => updateStatus("Completed")}>Completed</Button>
    </ButtonGroup>
    <Button color='secondary' onClick={showLogs}>Show Logs</Button>


    <Box m={2}>
      <Button onClick={() => props.history.push(`/queue/queue/${queueDetail.queueId}`)} text="Navigate To Queue" />
    </Box>

    <Grid container spacing={2}>
      <Grid item xs={6}>
        <PropertiesDisplay object={queueDetail} hideKeys={['queueProcessor', 'queueReport', 'queuePayroll', 'queueEmail', 'queue']} />
      </Grid>
      <Grid item xs={6}>
        <PropertiesDisplay object={queueDetailExt} hideKeys={['queueDetail']} />
      </Grid>
    </Grid>
  </Box>
}

export default QueueDetailItem;
