import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import axios from 'axios';

const options = [
    'Order Supplies',
    'Manuals And Voids',
    'Reopen Payroll',
    'Send Reports Password'
];

const ITEM_HEIGHT = 48;

class ButtonsPanel extends React.Component {
    state = {
        anchorEl: null
    }

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = (args) => {
        const { zafClient, service } = this.props;
        this.setState({ anchorEl: null });
        if (args && typeof args === 'string') {
            console.log(args);
            if (args === 'Send Reports Password') {
                service.sendReportsPassword(zafClient, this.props.conum);
            } else if (args === 'Manuals And Voids') {
                axios.post(`/api/FdUtils/send?methodName=sendManualsAndVoids&userid=${this.props.userExternalId}&conum=${this.props.conum}&arg1=${this.props.ticketId}`)
                    .then(data => {
                        console.log(data);
                    }).catch(err => {
                        this.props.zafClient.invoke('notify', 'error openning compnay ' + this.props.conum + 'in FD ' + err);
                    });
            } else {
                this.openFormInFD(args);
            }
        }
    };

    openFormInFD = (args) => {
        this.props.service.openFormInFD(this.props.conum, this.props.userExternalId, this.props.zafClient, args);
    }

    openReportsBrowserInFD = () => {
        axios.post(`/api/FdUtils/send?methodName=sendReports&userid=${this.props.userExternalId}&conum=${this.props.conum}&arg1=${this.props.ticketId}`)
            .then(data => {
                console.log(data);
            }).catch(err => {
                this.props.zafClient.invoke('notify', 'error openning compnay ' + this.props.conum + 'in FD ' + err);
            });
    }

    openTicketInFD = async (mode) => {
        let data = await this.props.zafClient.get('ticket.comments');
        let comment = data['ticket.comments'][0].value.replace(/<(?:.|\n)*?>/gm, '');
        let totalAttachments = data['ticket.comments'].map(a => a.nonImageAttachments.length).reduce((a, b) => a + b);

        let payload = {
            ticketId: this.props.ticketId,
            userId: this.props.userExternalId,
            comment: comment,
            conum: this.props.conum,
            requesterEmail: this.props.requester.email,
            totalAttachments,
            mode
        };
        this.props.service.openTicketInFD(this.props.zafClient, payload);
    }

    showSetTicketOrgModal = () => {
        let zafClient = this.props.zafClient;
        let props = this.props;

        zafClient.on('modalReady', function modalReady(d) {
            console.log('[ButtonsPanel] triggered modalReady');
            zafClient.instance(d).trigger('setSearchText', { email: props.requester.email, ticketId: props.ticketId });
            zafClient.off('modalReady', modalReady);
        });

        let args = encodeURI(`email=${props.requester.email}&ticketId=${props.ticketId}`);
        this.props.zafClient.invoke('instances.create', {
            location: 'modal',
            url: window.location.origin + `/setticketorg?${args}`
        }).then(function (modalContext) {
            var modalClient = zafClient.instance(modalContext['instances.create'][0].instanceGuid);
            modalClient.invoke('resize', { width: '80vw', height: '80vh' });
        });
    }

    render() {
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);
        return (
            <div>
                <Grid container>
                    <Grid item xs={10}>
                        <Button variant="contained"
                            fullWidth
                            color="primary"
                            disabled={this.props.disabled}
                            onClick={() => this.openFormInFD('Open Company')}>
                            Open Company {this.props.conum}
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <IconButton
                            aria-label="More"
                            aria-owns={open ? 'long-menu' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleClick}
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                        <Menu
                            id="long-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={this.handleClose}
                            PaperProps={{
                                style: {
                                    maxHeight: ITEM_HEIGHT * 4.5,
                                    width: 200,
                                },
                            }}
                        >
                            {options.map(option => (
                                <MenuItem key={option} onClick={() => this.handleClose(option)}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Grid>
                    <Grid item xs={10}>
                        <div style={{ marginTop: 5 }}>
                            <Button variant="contained"
                                color="primary"
                                fullWidth
                                disabled={this.props.disabled}
                                onClick={() => this.openTicketInFD('newPayroll')}>
                                New Payroll {this.props.conum}
                            </Button>
                        </div>
                    </Grid>
                    <Grid item xs={10}>
                        <div style={{ marginTop: 5 }}>
                            <Button variant="contained"
                                color="primary"
                                fullWidth
                                onClick={() => this.openTicketInFD('setCo')}>
                                Set Co# With Preview in FD
                                </Button>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div style={{ marginTop: 5 }}>
                            <Button variant="contained"
                                color="primary"
                                fullWidth
                                onClick={this.showSetTicketOrgModal}>Set Co#</Button>
                        </div>
                    </Grid>

                    <Grid item xs={10}>
                        <div style={{ marginTop: 5 }}>
                            <Button variant="contained"
                                color="primary"
                                fullWidth
                                onClick={this.openReportsBrowserInFD}>Send Reports</Button>
                        </div>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default ButtonsPanel;