import axios from 'axios';

const zendeskClientService = {
    openFormInFD(conum, userIdExternalId, zafClient, args) {
        axios.post(`/api/FdUtils/OpenFormInFD?conum=${conum}&args=${args}&userid=${userIdExternalId}`)
            .then(data => {
                console.log(data);
            }).catch(err => {
                zafClient.invoke('notify', 'error openning compnay ' + conum + 'in FD ' + err);
            });
    },

    openTicketInFD(zafClient, payload) {
        axios.post('/api/FdUtils/OpenTicketInFD', payload)
            .then(d => { }).catch(err => {
                zafClient.invoke('notify', 'error setting TicketConum in FD ' + err);
            });
    },

    setTicketAssignedTo(ticketId, userId, zafClient) {
        let ticketUpdate = {
            ticket: {
                assignee_id: userId
            }
        };

        var req = {
            url: '/api/v2/tickets/' + ticketId,
            method: 'PUT',
            contentType: 'application/json',
            data: JSON.stringify(ticketUpdate)
        };
        zafClient.request(req).then(function (ticketUpdateData) { });
    },

     sendReportsPassword(zafClient, conum) {
        axios.get(`/api/FdUtils/GetReportsPassword?conum=${conum}`).then(async data => {
            await zafClient.invoke('macro', 360065689893);
            let result = await zafClient.get('comment.text');
            console.log(result);
            let text = result['comment.text'];
            console.log('original text',text);
            text = text.replace('[password]', data.data);
            console.log('replaced text', text);
            zafClient.set('comment.text', text);
            console.log(data);
        }).catch(err => {
            zafClient.invoke('notify', 'error sending reports password');
        });
    }
};

export default zendeskClientService;