import React, { useState } from "react";
import ProcessorsList from "./Processors/ProcessorsList";
import QueuePage from './Queue/QueuePage';
import QueueDetailsPage from './QueueDetails/QueueDetailsPage';
import { Route, Link, Switch } from "react-router-dom";
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';

import clsx from 'clsx';
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.compact.css";
import { Toolbar, Typography, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import RabbitMq from './RabbitMq';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));

const Link1 = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

const getIsSelected = (routeName, currentRout) => {
  if (currentRout.startsWith('/queue/' + routeName + '/')) {
    return true;
  } else {
    return false;
  }
}

function QueueLayout(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = useState(true);
  const changeDrawerState = () => {
    setDrawerOpen(!drawerOpen);
  }

  return (
    <ThemeProvider>
      <div className={classes.root}>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar>
            <IconButton edge="start" color="inherit" aria-label="Open Drawer" onClick={changeDrawerState}>
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap >
              Brands Queue
          </Typography>
          </Toolbar>
        </AppBar>
        <Drawer variant="persistent" open={drawerOpen} className={classes.drawer} anchor="left" classes={{
          paper: classes.drawerPaper,
        }}>
          <div className={classes.appBarSpacer} />
          <List>
            <ListItem button component={Link1} to={`${props.match.url}/processors`} selected={getIsSelected('processors', props.location.pathname)}>
              <ListItemText primary="Processors" />
            </ListItem>
            <ListItem button component={Link1} to={`${props.match.url}/queue/list`} selected={getIsSelected('queue', props.location.pathname)}>
              <ListItemText primary="Queues" />
            </ListItem>
            <ListItem button component={Link1} to={`${props.match.url}/queueDetails/list`} selected={getIsSelected('queueDetails', props.location.pathname)}>
              <ListItemText primary="Queue Details" />
            </ListItem>
            <ListItem button component={Link1} to='rabbitmq' selected={getIsSelected('rabbitmq', props.location.pathname)}>
              <ListItemText primary="Rabbit Mq" />
            </ListItem>
          </List>
        </Drawer>
        <main className={clsx(classes.content, { [classes.contentShift]: drawerOpen, })}>
          <div className={classes.appBarSpacer} />
          <Route
            path={`${props.match.url}/processors`}
            component={ProcessorsList}
          />
          <Switch>
            <Route path={`${props.match.url}/queue`} component={QueuePage} />
            <Route path={`${props.match.url}/queueDetails`} component={QueueDetailsPage} />
            <Route path={`${props.match.url}/rabbitmq`} component={RabbitMq} />
          </Switch>
        </main>
      </div>
    </ThemeProvider>
  );
}

export default QueueLayout;
