import React, { useState } from "react";
import QueueDetailItem from './QueueDetailItem';
import { Route, Link, Switch } from "react-router-dom";
import QueueDetailsList from "./QueueDetailsList";

function QueueDetailsPage() {
    let d = new Date();
    d.setDate(d.getDate() - 2);
    const [fromDate, setFromDate] = useState(d);

    return (
        <div>
            <Switch>
                <Route path='/queue/queueDetails/list' render={(props => <QueueDetailsList fromDate={fromDate}/>)} />
                <Route path='/queue/queueDetails/:Id' component={QueueDetailItem} />
            </Switch>
        </div>
    )
}

export default QueueDetailsPage