import React from 'react';
import ButtonsPanel from './buttonsPanel';
import service from '../zendeskClientService';
import ticketInfo from '../ticketInfo';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    progress: {
        margin: theme.spacing.unit * 2,
    },
});

class TicketSidbar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: true,
            ticketId: null,
            organization: null,
            conum: null,
            userExternalId: null,
            userId: null,
            zafClient: null
        };
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    async componentDidMount() {
        let ti = await ticketInfo();
        let context = this;
        this.setState(ti);
        ti.zafClient.on('ticket.updated', async function (user) {
            context.setState(await ticketInfo());
        });
    }

    render() {
        const { classes } = this.props;
        let content = <CircularProgress className={classes.progress} />;
        if (this.state.ticketId) {
            content = (
                <ButtonsPanel {...this.state}
                    disabled={this.state.conum === null}
                    service={service}
                />);
        }
        return (content);
    }
}

export default withStyles(styles)(TicketSidbar);