import React, { useState, useEffect, useRef } from "react";
import { HubConnectionBuilder } from "@aspnet/signalr";
import DataGrid, {
    Column,
    Sorting,
    GroupPanel,
    SearchPanel,
    FilterPanel,
    HeaderFilter,
    ColumnChooser,
    Pager,
    StateStoring,
    Selection
} from "devextreme-react/data-grid";
import axios from 'axios';
import { Box, Button, ButtonGroup, LinearProgress, Paper, Typography } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/core/styles';
import HistoryIcon from '@material-ui/icons/History';
import StopIcon from '@material-ui/icons/Stop';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import notify from 'devextreme/ui/notify';

const useStyles = makeStyles(theme => ({
    button: {
        margin: theme.spacing(0),
    },
    input: {
        display: 'none',
    },
}));


function ProcessorsList() {
    const connectionsRef = useRef([]);
    const [connections, setConnections] = useState([]);
    const [selectedRowKeys, SetSelectedRowKeys] = useState([]);
    const [apiLoading, SetApiLoading] = useState(false);
    const classes = useStyles();


    const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
        SetSelectedRowKeys(selectedRowKeys);
    }

    function CellButton(props) {
        return (<div >
            <IconButton className={classes.button} aria-label="delete" onClick={() => showLogs(props)}>
                <HistoryIcon fontSize="small" />
            </IconButton>
            <IconButton className={classes.button} aria-label="delete" onClick={() => processorSignalRCommand('ResumeNode', props.row.data.appGuid)}>
                <PlayArrowIcon fontSize="small" />
            </IconButton>
            <IconButton className={classes.button} aria-label="delete" onClick={() => processorSignalRCommand('DrainNode', props.row.data.appGuid)}>
                <StopIcon fontSize="small" />
            </IconButton>
            <IconButton className={classes.button} aria-label="delete" onClick={() => processorSignalRCommand('UpdateNode', props.row.data.appGuid)}>
                <SystemUpdateAltIcon fontSize="small" />
            </IconButton>
        </div>)
    }

    useEffect(() => {
        axios.get('/api/Queue/ProcessorsConnections').then(data => {
            connectionsRef.current = data.data;
            setConnections(data.data)
        }).catch(err =>
            console.log(err)
        );
    }, []);

    useEffect(() => {
        console.log("test");
        var connection = new HubConnectionBuilder()
            .withUrl("/hub/QueueHub")
            .build();
        connection.on("Connected", data => {
            let list = connectionsRef.current.concat(data);
            setConnections(list);
            connectionsRef.current = list;
        });

        connection.on("Disconnected", data => {
            let list = [...connectionsRef.current];
            let filtered = list.filter(
                (value, index) => value.connectionId !== data.connectionId
            );
            setConnections(filtered);
            connectionsRef.current = filtered;
        });

        connection.on("Updated", data => {
            let list = [...connectionsRef.current];
            let index = list.findIndex(
                value => value.connectionId === data.connectionId
            );
            if (index === -1) {
                list.unshift(data);
            } else {
                list[index] = data;
            }
            setConnections(list);
            connectionsRef.current = list;
        });

        const start = async () => {
            try {
                await connection.start();
                connection.invoke("StatusUpdateAsync", { operationName: "Web" });
            } catch (err) {
                setTimeout(() => start(), 5000);
            }
        };

        let shouldAutoStart = true;
        connection.onclose(function (error) {
            if (shouldAutoStart) {
                start();
            }
        });
        start();
        return function cleanUp() {
            shouldAutoStart = false;
            connection.stop();
        };
    }, []);

    const showLogs = e => {
        window.open(`http://appserver:5341/#/events?signal=signal-193&filter=AppInstanceId%20%3D%20'${e.row.data.appGuid}'&autorefresh`, "_blank");
    };

    const processorSelectedRowsSignalRCommand = (commandName) => {
        SetApiLoading(true);
        selectedRowKeys.forEach(id => {
            processorSignalRCommand(commandName, id)
        });
        SetApiLoading(false);
    }

    const processorSignalRCommand = (commandName, appGuid) => {
        axios.post('/api/Queue/ProcessorSignalRCommand', { commandName, appGuid })
            .then(data => {
                console.log(data);
            }
            ).catch(err => {
                notify(err.response, 'error', 800)
            });
    }

    return (
        <Box p={2}>
            {selectedRowKeys && selectedRowKeys.length > 0 &&
                <Paper>
                    <Box m={1}>
                        <Typography onClick={() => SetSelectedRowKeys([])} >A total of {selectedRowKeys.length} rows are selected</Typography>
                        <Box display="flex" alignItems="flex-end">
                            <Box>
                                {apiLoading && <LinearProgress />}
                            </Box>
                            <Box style={{ flex: 1 }} py={2}>
                                <ButtonGroup variant="contained" aria-label="contained button group">
                                    <Button onClick={() => processorSelectedRowsSignalRCommand("UpdateNode")}>Update</Button>
                                    <Button onClick={() => processorSelectedRowsSignalRCommand("ResumeNode")}>Resume</Button>
                                    <Button onClick={() => processorSelectedRowsSignalRCommand("DrainNode")}>Drain</Button>
                                </ButtonGroup>
                            </Box>
                        </Box>
                    </Box>
                </Paper>
            }

            <DataGrid
                keyExpr="appGuid"
                onSelectionChanged={onSelectionChanged}
                columnAutoWidth={true}
                columnResizingMode={"widget"}
                highlightChanges
                showBorders
                dataSource={connections}
                showColumnLines
                showRowLines
                allowColumnReordering
                allowColumnResizing
                rowAlternationEnabled
                hoverStateEnabled
                repaintChangesOnly
            >
                <SearchPanel visible={true} highlightCaseSensitive={true} />
                <GroupPanel visible={true} />
                <Sorting mode={'multiple'} />
                <FilterPanel visible />
                <HeaderFilter visible />
                <ColumnChooser enabled />
                <Pager showPageSizeSelector showInfo />
                <StateStoring enabled type='localStorage' storageKey='queueProcessorsListGrid' />
                <Selection
                    mode='multiple'
                    selectAllMode='allPages'
                    showCheckBoxesMode='onClick' />

                <Column cellRender={CellButton} />
                <Column dataField="operationName" />
                <Column dataField={"status"} dataType={"string"} />
                <Column dataField="message" />

                <Column
                    dataField="connectedOn"
                    dataType="date"
                    format="MM/dd/yyyy hh:mm a"
                />
                <Column dataField="version" />
                <Column dataField="machineName" />
                <Column dataField="connectionId" visible={false} />
                <Column dataField="appGuid" visible={false} />
                <Column
                    dataField="startedOn"
                    dataType="date"
                    format="MM/dd/yyyy hh:mm a"
                />
                <Column
                    dataField="lastIdleTime"
                    dataType="date"
                    format="MM/dd/yyyy hh:mm a"
                />
                <Column dataField="queueId" />
                <Column dataField="queueDetailId" />
            </DataGrid>
        </Box>
    );
}

export default ProcessorsList;
