import React, { useState } from "react";
import QueueList from './QueueList';
import QueueItem from './QueueItem';
import { Route, Switch } from "react-router-dom";
import useFetchQueue from './useFetchQueue';

function QueuePage() {
    let d = new Date();
    d.setDate(d.getDate() - 2);
    const [fromDate, setFromDate] = useState(d);
    const [filter, setFilter] = useState(d);
    const { queues, isLoading } = useFetchQueue({ fromDate, filter });

    return (
        <div>
            <Switch>
                <Route path='/queue/queue/list' render={(props => 
                <QueueList 
                    queues={queues} 
                    isLoading={isLoading} 
                    setFromDate={setFromDate} 
                    fromDate={fromDate} 
                    filter={filter}
                    setFilter={setFilter}/>)} />
                <Route path='/queue/queue/:Id' component={QueueItem} />
            </Switch>
        </div>
    )
}

export default QueuePage
