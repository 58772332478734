import { useEffect, useRef, useState } from 'react'
import axios from "axios";
import { HubConnectionBuilder } from "@aspnet/signalr";

export default function useFetchQueue(props) {
    const queueRef = useRef([]);
    const [queues, setQueues] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let url = '/api/Queue/Queues';

        if (props.queueId) {
            url = `api/Queue/Queues?queueId=${props.queueId}`;
        }
        setIsLoading(true);
        axios
            .get(url, {
                params: { fromDate: props.fromDate, ...props.filter }
            })
            .then(data => {
                queueRef.current = data.data;
                setQueues(data.data);
            })
            .catch(err => {
                console.log(err)
            }).finally(function () {
                setIsLoading(false);
            });
    }, [props.queueId, props.fromDate]);

    useEffect(() => {
        var connection = new HubConnectionBuilder()
            .withUrl("/hub/QueueHub")
            .build();

        connection.on("QueueUpdated", data => {
            if (props.queueId) {
                if(data.id == props.queueId){
                    setQueues([data]);
                }
            } else {
                let list = [...queueRef.current];
                let index = list.findIndex(
                    value => value.id === data.id
                );
                if (index === -1) {
                    list.unshift(data);
                } else {
                    list[index] = data;
                }

                setQueues(list);
                queueRef.current = list;
            }
        });

        const start = async () => {
            try {
                await connection.start();
                connection.invoke("StatusUpdateAsync", { operationName: "Web" });
            } catch (err) {
                setTimeout(() => start(), 5000);
            }
        };

        let shouldAutoStart = true;
        connection.onclose(function (error) {
            if (shouldAutoStart) {
                start();
            }
        });
        start();
        return function cleanUp() {
            shouldAutoStart = false;
            connection.stop();
        };
    }, []);

    function setQueue(queue) {
        setQueues([queue]);
    }

    return {
        queues, isLoading, setQueue
    };
}