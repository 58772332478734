import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableRow, TableCell, TableContainer, Typography, Paper } from '@material-ui/core';

export default function PropertiesDisplay(props) {
    const [rows, setRows] = useState([]);

    useEffect(() => {
        if (props.object) {
            let keys = Object.keys(props.object);
            if (props.hideKeys) {
                keys = keys.filter(k => !props.hideKeys.includes(k));
            }
            setRows(keys.map(k => {
                return {
                    name: k,
                    value: props.object[k]
                }
            }))
        }
    }, [props.object, props.hideKeys]);

    return (
        <TableContainer component={Paper} style={{ width: 'auto', display: 'inline-block', margin: 5 }}>
            <Table size='small' style={{ width: 'auto' }}>
                <TableBody>
                    {rows && rows.map(r => (
                        <TableRow key={r.name}>
                            <TableCell><Typography variant='subtitle2'>{r.name}:</Typography></TableCell>
                            <TableCell align='left'><Typography variant='body2'>{r.value}</Typography></TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}