import React, { useState } from "react";
import DataGrid, {
  Column,
  Sorting,
  GroupPanel,
  SearchPanel,
  FilterPanel,
  ColumnChooser,
  HeaderFilter,
  Grouping,
  StateStoring,
  Pager,
  Selection
} from "devextreme-react/data-grid";
import useFetchQueueDetails from './useFetchQueueDetails';
import { Box, Button, Paper, Typography, ButtonGroup, Drawer } from "@material-ui/core";
import QueueDetailItem from './QueueDetailItem'
import axios from 'axios';

export default function QueueDetailsGrid(props) {
  const queueDetails = useFetchQueueDetails(props);
  const [popupVisible, setPopupVisible] = useState(false);
  const [editingQueueDetailId, setEditingQueueDetailId] = useState(null);
  const [selectedRowKeys, SetSelectedRowKeys] = useState([]);

  const editDetails = e => {
    setEditingQueueDetailId(e.row.data.id);
    setPopupVisible(true);
  };

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData }) => {
    SetSelectedRowKeys(selectedRowKeys);
  }

  const updateStatus = status => {
    selectedRowKeys.forEach(id =>{
      axios
      .post("/api/Queue/QueueDetailsUdpate", { id: id, status })
      .then(data => {
        
      })
      .catch(err => console.log(err));
    });
  };

  return (<React.Fragment>
    
    <Drawer anchor='right' 
        open={popupVisible} 
        onClose={() => setPopupVisible(false)} 
        width={'75vw'} 
        openSecondary={true}
        docked={true}>
        <div style={{ width: '75vw' }}>
          {editingQueueDetailId && <QueueDetailItem id={editingQueueDetailId} />}
        </div>
      </Drawer>

      {/* <Popup
        closeOnOutsideClick
        visible={popupVisible}
        onHiding={() => setPopupVisible(false)}>
        <QueueDetailItem id={editingQueueDetailId} />
      </Popup> */}

    {selectedRowKeys && selectedRowKeys.length > 0 &&
      <Box px={1}>
        <Paper>
          <Box m={1}>
            <Typography >A total of {selectedRowKeys.length} rows are selected</Typography>
            <Box style={{ flex: 1 }} py={2}>
              <Typography component="p" color="textSecondary" inline>Update Queue Status</Typography>
              <ButtonGroup variant="contained" aria-label="contained button group">
                <Button onClick={() => updateStatus("Pending")}>Pending</Button>
                <Button onClick={() => updateStatus("Completed")}>Completed</Button>
                <Button onClick={() => updateStatus("Cancel")}>Cancel</Button>
              </ButtonGroup>
            </Box>
          </Box>
        </Paper>
      </Box>
    }

    <DataGrid
      keyExpr="id"
      columnAutoWidth={true}
      columnResizingMode={"widget"}
      highlightChanges
      showBorders
      dataSource={queueDetails}
      selectedRowKeys={selectedRowKeys}
      onSelectionChanged={onSelectionChanged}
      showColumnLines
      showRowLines
      allowColumnReordering
      allowColumnResizing
      rowAlternationEnabled
      hoverStateEnabled
      repaintChangesOnly
    >
      <SearchPanel visible={true} highlightCaseSensitive={true} />
      <GroupPanel visible={true} />
      <Grouping autoExpandAll />
      <Sorting mode={"multiple"} />
      <FilterPanel visible />
      <HeaderFilter visible />
      <ColumnChooser enabled />
      <StateStoring enabled type='localStorage' storageKey='queueDetailsListGrid' />
      <Pager showPageSizeSelector showInfo />
      <Selection
        mode='multiple'
        selectAllMode='allPages'
        showCheckBoxesMode='onClick' />

      <Column
        type={"buttons"}
        width={110}
        buttons={[
          {
            hint: "Edit",
            icon: "edit",
            onClick: editDetails
          }
        ]}
      />

      <Column dataField="status" />
      <Column dataField="id" />
      <Column dataField="queueId" />
      <Column dataField="queueTypeName" />
      <Column dataField="queueSubTypeName" />
      <Column dataField="order" />
      <Column dataField="completedOn" dataType="date"
        format="MM/dd/yyyy hh:mm a" />
      <Column dataField="processingDuration" />
      <Column dataField="processedByMachineName" />
      <Column dataField="processedByAppGuid" />
      <Column dataField="errorCount" />
      <Column dataField="errorMessage" />
      <Column dataField="errorDateTime" dataType="date"
        format="MM/dd/yyyy hh:mm a" />

      <Column dataField="prnum" />
      <Column dataField="empnum" />
      <Column dataField="udfNumber1" />
      <Column dataField="correlationId" groupIndex={0} />
    </DataGrid>
  </React.Fragment>
  );
}
