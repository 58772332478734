import { useEffect, useRef, useState } from 'react'
import axios from "axios";
import { HubConnectionBuilder } from "@aspnet/signalr";

export default function useFetchQueueDetails(props) {
    const queueDetailsRef = useRef([]);
    const [queueDetails, setQueueDetails] = useState([]);

    useEffect(() => {
        let url = '/api/Queue/QueueDetails';
        if (props.queueId) {
            url = `api/Queue/QueueDetails?queueId=${props.queueId}`;
        }

        axios
            .get(url, {
                params: { fromDate: props.fromDate }
            })
            .then(data => {
                queueDetailsRef.current = data.data;
                setQueueDetails(data.data);
            })
            .catch(err => console.log(err));
    }, [props.queueId]);

    useEffect(() => {
        var connection = new HubConnectionBuilder()
            .withUrl("/hub/QueueHub")
            .build();

        connection.on("QueueDetailUpdated", data => {
            if (props.queueId && data.queueId !== props.queueId) {
                return;
            }

            let list = [...queueDetailsRef.current];
            let index = list.findIndex(
                value => value.id === data.id
            );
            if (index === -1) {
                list.unshift(data);
            } else {
                list[index] = data;
            }

            //how to add
            // let list = connectionsRef.current.concat(data);
            //how to remove an item
            // let filtered = list.filter(
            //     (value, index) => value.connectionId !== data.connectionId
            // );
            setQueueDetails(list);
            queueDetailsRef.current = list;
        });

        const start = async () => {
            try {
                await connection.start();
                connection.invoke("StatusUpdateAsync", { operationName: "Web" });
            } catch (err) {
                setTimeout(() => start(), 5000);
            }
        };

        let shouldAutoStart = true;
        connection.onclose(function (error) {
            if (shouldAutoStart) {
                start();
            }
        });
        start();
        return function cleanUp() {
            shouldAutoStart = false;            
            connection.stop();
        };
    }, [props.fromDate, props.queueId]);

    return queueDetails;
}